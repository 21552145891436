import "core-js/modules/es.array.push.js";
import { createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-fecce7a6"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["src"];
import nav_header from '@/components/nav_header.vue';

import { useRouter } from 'vue-router';
import { ref, onBeforeMount } from 'vue';
export default {
  __name: 'chart',
  setup(__props) {
    let router = useRouter();
    let url = ref('');
    onBeforeMount(() => {
      if (JSON.parse(localStorage.getItem('chat')) == null) {
        router.push({
          path: '/login'
        });
      } else {
        let token = JSON.parse(localStorage.getItem('chat')).token;
        url.value = `https://48k.pinglun66.vip/sp/amzl.html?t=${token}`;
      }
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(nav_header, {
        name: "聊天"
      }), _createElementVNode("iframe", {
        src: _unref(url),
        frameborder: "0"
      }, null, 8, _hoisted_1)], 64);
    };
  }
};