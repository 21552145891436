import "core-js/modules/es.array.push.js";
import { createVNode as _createVNode, unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, isRef as _isRef, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-29570a67"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "switch"
};
const _hoisted_2 = {
  class: "cont"
};
const _hoisted_3 = {
  class: "list"
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
  key: 0,
  class: "img"
};
const _hoisted_6 = ["src"];
const _hoisted_7 = {
  key: 1,
  class: "img",
  style: {
    "width": "40px",
    "height": "40px",
    "overflow": "hidden",
    "border-radius": "100%"
  }
};
const _hoisted_8 = ["src"];
const _hoisted_9 = {
  class: "info"
};
const _hoisted_10 = {
  key: 0,
  class: "names"
};
const _hoisted_11 = {
  class: "title"
};
const _hoisted_12 = {
  class: "desc"
};
const _hoisted_13 = {
  key: 1,
  class: "names"
};
const _hoisted_14 = {
  class: "title"
};
const _hoisted_15 = ["innerHTML"];
const _hoisted_16 = ["onClick"];
import nav_header from '@/components/nav_header.vue';
import { article_store, cancel_article } from '@/util/http.js';
import axios from 'axios';

import { onBeforeMount, ref, reactive } from 'vue';
import { useRouter } from 'vue-router';
export default {
  __name: 'collect',
  setup(__props) {
    onBeforeMount(() => {
      collect(2, 1);
    });
    const loading = ref(false);
    const finished = ref(false);
    let datas = reactive({
      collectList: [],
      page: 1,
      typeId: 2
    });
    const onLoad = () => {
      datas.page++;
      collect(datas.typeId, datas.page);
    };
    let collectList = reactive([]);
    let activeIndex = ref('2');
    const router = useRouter();
    let navFun = index => {
      activeIndex.value = index;
      datas.page = 1;
      datas.typeId = index;
      datas.collectList.length = 0;
      collect(index, datas.page);
    };
    let collect = (type, page) => {
      article_store({
        type: type,
        page: page
      }).then(res => {
        if (res.message == "用户未登录或已过期") {
          showToast(res.message);
          return false;
        }
        let data = res.data.data;
        if (data.length == 0) {
          finished.value = true;
        } else {
          console.log(data);
          loading.value = false;
          if (type == 2) {
            datas.collectList.push(...data);
          } else {
            datas.collectList.push(...data);
          }
        }
      });
    };
    // let gallery = (id)=>{
    //     let url = `https://jl.tukuapi.com/api/tuku_detail?id=${id}`
    //     axios.get(url).then(res=>{
    //         let data = res.data.data;

    //         datas.collectList.push(data)

    //     })
    // }
    let cancel = (id, index) => {
      //取消收藏
      cancel_article({
        article_id: id,
        type: activeIndex.value
      }).then(res => {
        collectList.splice(index, 1);
      });
    };
    let collectdesc = data => {
      if (activeIndex.value == 2) {
        router.push({
          path: `/gallery/detail/${data.amtk.id}/${data.amtk.issue}`
        });
      } else {
        router.push({
          path: `/detail/${data.article.id}/0`
        });
      }
    };
    return (_ctx, _cache) => {
      const _component_van_empty = _resolveComponent("van-empty");
      const _component_van_list = _resolveComponent("van-list");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(nav_header, {
        name: "我的收藏"
      }), _createElementVNode("div", _hoisted_1, [_createElementVNode("ul", null, [_createElementVNode("li", {
        class: _normalizeClass(_unref(activeIndex) == 2 ? 'acitve' : ''),
        onClick: _cache[0] || (_cache[0] = $event => _unref(navFun)(2))
      }, "快乐8图库", 2), _createElementVNode("li", {
        class: _normalizeClass(_unref(activeIndex) == 1 ? 'acitve' : ''),
        onClick: _cache[1] || (_cache[1] = $event => _unref(navFun)(1))
      }, "快乐8论坛", 2)])]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_van_list, {
        loading: _unref(loading),
        "onUpdate:loading": _cache[2] || (_cache[2] = $event => _isRef(loading) ? loading.value = $event : null),
        finished: _unref(finished),
        "finished-text": "没有更多了",
        onLoad: onLoad
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_unref(datas).collectList.length == 0 ? (_openBlock(), _createBlock(_component_van_empty, {
          key: 0,
          style: {
            "margin-top": "100px"
          },
          description: "目前还没有收藏哦"
        })) : (_openBlock(true), _createElementBlock(_Fragment, {
          key: 1
        }, _renderList(_unref(datas).collectList, (item, index) => {
          return _openBlock(), _createElementBlock("div", {
            class: "item",
            key: index,
            onClick: $event => _unref(collectdesc)(item)
          }, [_unref(activeIndex) == 2 ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("img", {
            src: item.amtk.image_path,
            alt: ""
          }, null, 8, _hoisted_6)])) : (_openBlock(), _createElementBlock("div", _hoisted_7, [_createElementVNode("img", {
            src: item.article.user.avatar,
            alt: ""
          }, null, 8, _hoisted_8)])), _createElementVNode("div", _hoisted_9, [_unref(activeIndex) == 2 ? (_openBlock(), _createElementBlock("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, _toDisplayString(item.amtk.name), 1), _createElementVNode("div", _hoisted_12, _toDisplayString(item.amtk.source_site), 1)])) : (_openBlock(), _createElementBlock("div", _hoisted_13, [_createElementVNode("div", _hoisted_14, _toDisplayString(item.article.title), 1), _createElementVNode("div", {
            class: "desc x",
            innerHTML: item.article.content
          }, null, 8, _hoisted_15)])), _createElementVNode("div", {
            class: "cares",
            onClick: _withModifiers($event => _unref(cancel)(item.id, index), ["stop"])
          }, "取消收藏", 8, _hoisted_16)])], 8, _hoisted_4);
        }), 128))])]),
        _: 1
      }, 8, ["loading", "finished"])])], 64);
    };
  }
};