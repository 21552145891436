import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { Lazyload } from 'vant';
import component from '@/util/component.js'// 引入公共组件
const app = createApp(App)

app.use(router);

// 注册时设置`lazyComponent`选项
app.use(Lazyload, {
    lazyComponent: true,
  });


app.use(component);
app.mount('#app')




