import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/main.vue'
import index from '../views/page/index.vue'
import find from '@/views/find/index.vue'
import competition from '@/views/competition/index.vue'
import platform from '@/views/platfrom/index.vue'
import me from '@/views/me/index.vue'
import login from '@/views/login.vue'
import register from '@/views/register.vue'
import video from '@/views/page/prize.vue'
import information from '@/views/page/information.vue'
import infoList from '@/views/page/infoList.vue'
import article from '@/views/page/article.vue'
import forum from '@/views/page/forum.vue'
import gallery from '@/views/page/gallery.vue'
import galleryDesc from '@/views/page/galleryDesc.vue'
import guess from '@/views/page/guess.vue'
import formula from '@/views/page/formula.vue'
import cares from '@/views/me/cares.vue'
import collect from '@/views/me/collect.vue'
import like from '@/views/me/like.vue'
import comment from '@/views/me/comment.vue'
import publish from '@/views/me/publish.vue'
import banList from '@/views/me/banList.vue'
import stationNews from '@/views/me/stationNews.vue';
import about from '@/views/me/about.vue'
import setup from '@/views/me/setup.vue'
import galleryList from '@/views/page/galleryList.vue'
import record from '@/views/page/record.vue'
import articleRecord from '@/views/page/articleRecord.vue'
import forumSearch from '@/views/page/forumSearch.vue'

import tools from '@/views/page/tools.vue'
import chart from '@/views/page/chart.vue'
import feedback from '@/views/me/feedback.vue'
import signin from '@/views/me/signin.vue'
import siginRecord from '@/views/me/siginRecord.vue'

import api from '@/views/me/api.vue'

const routes = [
    {
      path: '/',
      name: 'home',
      component: HomeView,
      redirect: '/index',
      children:[
        {
          path: '/index',
          name: 'index',
          component: index
        },

 
        
        {
          path: '/index',
          name: 'index',
          component: index
        },
        {
          path: '/find',
          name: 'find',
          component: find
        },
        {
          path: '/competition',
          name: 'competition',
          component: competition
        },
        {
          path: '/platform',
          name: 'platform',
          component: platform
        },
        {
          path: '/me',
          name: 'me',
          component: me
        },
      ]
    },

    {
      path: '/login',
      name: 'login',
      component: login
    },
    {
      path: '/register',
      name: 'register',
      component: register
    },
    {
      path: '/forum',
      name: 'forum',
      component: forum
    },
    {
      path: '/gallery',
      name: 'gallery',
      component: gallery,
      meta:{
        keepAlive:true
      }
      
    },
    {
      path: '/gallery/detail/:id/:qi',
      name: 'galleryDesc',
      component: galleryDesc
    },
    {
      path: '/detail/:id/:type',
      name: 'article',
      component: article
    },
    {
      path: '/information/list/:id/:name',
      name: 'infoList',
      component: infoList
    },
    {
      path: '/guess',
      name: 'guess',
      component: guess
    },
    {
      path: '/formula',
      name: 'formula',
      component: formula
    },
    {
      path: '/cares',
      name: 'cares',
      component: cares
    },
    {
      path: '/collect',
      name: 'collect',
      component: collect
    },
    {
      path: '/like',
      name: 'like',
      component: like
    },
    {
      path: '/comment',
      name: 'comment',
      component: comment
    },
    {
      path: '/publish',
      name: 'publish',
      component: publish
    },
    {
      path: '/banList',
      name: 'banList',
      component: banList
    },
    {
      path: '/stationNews',
      name: 'stationNews',
      component: stationNews
    },
    {
      path: '/about',
      name: 'about',
      component: about
    },
    {
      path: '/video',
      name: 'video',
      component: video
    },
    {
      path: '/information',
      name: 'information',
      component: information
    },

    {
      path: '/gallery/list',
      name: 'gallery/list',
      component: galleryList,
      meta:{
        keepAlive:true
      }
    },
    {
      path: '/setup',
      name: 'setup',
      component: setup
    },
    {
      path: '/record',
      name: 'record',
      component: record
    },
    {
      path: '/articleRecord/:id',
      name: 'articleRecord',
      component: articleRecord
    },
    {
      path: '/forumSearch',
      name: 'forumSearch',
      component: forumSearch
    },
    {
      path: '/tools',
      name: 'tools',
      component: tools
    },
    {
      path: '/chart',
      name: 'chart',
      component: chart
    },
    {
      path: '/feedback',
      name: 'feedback',
      component: feedback
    },
    {
      path: '/signin',
      name: 'signin',
      component: signin
    },
    {
      path: '/signin/record',
      name: 'siginRecord',
      component: siginRecord
    },
    {
      path: '/api',
      name: 'api',
      component: api
    },
    
    
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
      next("/index");
    } else {
      next();
    }
})
export default router
