import "core-js/modules/es.array.push.js";
import { createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, isRef as _isRef, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-20acd138"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "van-list"
};
const _hoisted_2 = ["onClick"];
const _hoisted_3 = {
  class: "info"
};
const _hoisted_4 = ["src"];
const _hoisted_5 = {
  class: "data"
};
const _hoisted_6 = {
  class: "name"
};
const _hoisted_7 = {
  style: {
    "color": "#999"
  }
};
const _hoisted_8 = {
  class: "title"
};
import nav_header from '@/components/nav_header.vue';
import swipers from '@/components/swipers.vue';
import search from '@/components/search.vue';
import register from '@/components/release.vue';
import { article_type, article_index, article_add } from '@/util/http';
import { showToast } from 'vant';
import 'vant/es/toast/style';

import { useRouter } from 'vue-router';
import { reactive, ref, onBeforeMount } from 'vue';
export default {
  __name: 'formula',
  setup(__props) {
    const router = useRouter();
    let formulaArray = reactive([]);
    let formuladesc = data => {
      router.push({
        path: `/detail/${data.id}/0`
      });
    };
    let left = ref('100');
    let showss = ref(false);
    let releasc = () => {
      left.value = '0';
    };
    let returnUp = () => {
      left.value = '100';
    };
    let submit = data => {
      console.log(tid.value);
      articleAdd(data.title, data.content, tid.value);
    };
    let onSubmit = data => {
      formulaArray.length = 0;
      page.value == 1;
      articleIndex(tid.value, 1, data);
    };
    let articleAdd = (title, content, id) => {
      article_add({
        title: title,
        content: content,
        type_id: id
      }).then(res => {
        if (res.code == '320') {
          showss.value = true;
          left.value = '100';
          return false;
        }
        if (res.code == '400') {
          showToast(res.message);
          return;
        }
        if (res.message == '请先登录!') {
          showToast(res.message);
        } else {
          showToast(res.message);
          left.value = '100';
          location.reload();
        }
      });
    };
    const loading = ref(false);
    const finished = ref(false);
    let page = ref(1);
    let tid = ref('');
    onBeforeMount(() => {
      article();
    });
    let article = () => {
      article_type({
        is_ym: 2
      }).then(res => {
        let id = res.data[0].id;
        tid.value = id;
        articleIndex(id, 1);
      });
    };
    let articleIndex = (id, pages, content = '') => {
      article_index({
        type_id: id,
        page: pages,
        search: content
      }).then(res => {
        let data = res.data.data;
        if (data.length == '0') {
          finished.value = true;
        } else {
          loading.value = false;
          formulaArray.push(...data);
        }
      });
    };
    let onLoad = () => {
      // article_index(tid.value,page.value)\
      page.value++;
      articleIndex(tid.value, page.value);
    };
    return (_ctx, _cache) => {
      const _component_van_list = _resolveComponent("van-list");
      const _component_logos = _resolveComponent("logos");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(nav_header, {
        name: "六合公式"
      }), _createVNode(swipers), _createVNode(search, {
        onSubmit: _unref(onSubmit)
      }, null, 8, ["onSubmit"]), _createVNode(_component_van_list, {
        loading: _unref(loading),
        "onUpdate:loading": _cache[0] || (_cache[0] = $event => _isRef(loading) ? loading.value = $event : null),
        finished: _unref(finished),
        "finished-text": "没有更多了",
        onLoad: _unref(onLoad)
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(formulaArray), (item, index) => {
          return _openBlock(), _createElementBlock("li", {
            key: index,
            onClick: $event => _unref(formuladesc)(item)
          }, [_createElementVNode("div", _hoisted_3, [_createElementVNode("img", {
            src: item.username.avatar,
            alt: ""
          }, null, 8, _hoisted_4), _createElementVNode("div", _hoisted_5, [_createElementVNode("span", _hoisted_6, _toDisplayString(item.username.nickname), 1), _createElementVNode("span", _hoisted_7, _toDisplayString(item.create_time), 1)])]), _createElementVNode("div", _hoisted_8, _toDisplayString(item.title), 1)], 8, _hoisted_2);
        }), 128))])])]),
        _: 1
      }, 8, ["loading", "finished", "onLoad"]), _createVNode(register, {
        types: false,
        onReleases: _unref(releasc),
        onReturnUp: _unref(returnUp),
        onSubmit: _unref(submit),
        left: _unref(left)
      }, null, 8, ["onReleases", "onReturnUp", "onSubmit", "left"]), _createVNode(_component_logos, {
        shows: _unref(showss),
        "onUpdate:shows": _cache[1] || (_cache[1] = $event => _isRef(showss) ? showss.value = $event : showss = $event)
      }, null, 8, ["shows"])], 64);
    };
  }
};