import { createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-a2943a40"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "link_max"
};
const _hoisted_2 = {
  class: "wrap"
};
const _hoisted_3 = ["href"];
const _hoisted_4 = {
  class: "img"
};
const _hoisted_5 = ["src"];
const _hoisted_6 = {
  class: "name"
};
const _hoisted_7 = {
  class: "old_link_max"
};
const _hoisted_8 = {
  class: "wrap"
};
const _hoisted_9 = {
  class: "name"
};
const _hoisted_10 = ["href"];
import nav_header from '@/components/nav_header.vue';
import { adv } from '@/util/http';

import { reactive, toRefs } from 'vue';
export default {
  __name: 'index',
  setup(__props) {
    let data = reactive({
      linkArray: [],
      urlArray: []
    });
    let {
      linkArray,
      urlArray
    } = toRefs(data);
    adv({
      type: 2
    }).then(res => {
      data.linkArray = res.data;
    });
    adv({
      type: 3
    }).then(res => {
      data.urlArray = res.data;
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(nav_header, {
        name: "网站大全",
        iconleft: false
      }), _createElementVNode("div", _hoisted_1, [_createElementVNode("ul", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(linkArray), (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          key: index
        }, [_createElementVNode("a", {
          href: item.links
        }, [_createElementVNode("div", _hoisted_4, [_createElementVNode("img", {
          src: item.url,
          alt: ""
        }, null, 8, _hoisted_5)]), _createElementVNode("p", _hoisted_6, _toDisplayString(item.describe), 1)], 8, _hoisted_3)]);
      }), 128))]), _createElementVNode("div", _hoisted_7, [_createElementVNode("ul", _hoisted_8, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(urlArray), (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          key: index
        }, [_createElementVNode("p", _hoisted_9, [_createElementVNode("a", {
          href: item.links
        }, _toDisplayString(item.describe), 9, _hoisted_10)])]);
      }), 128))])])])], 64);
    };
  }
};