import axios from 'axios';
export default {
  name: 'Date',
  props: {
    type: 0
  },
  data() {
    return {
      api: 'https://ls.kjkj.fit',
      // api:'',
      weeks: ['日', '一', '二', '三', '四', '五', '六'],
      kind: 0,
      year: new Date().getFullYear(),
      ny: new Date().getFullYear(),
      iy: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      nm: new Date().getMonth() + 1,
      im: new Date().getMonth() + 1,
      day: new Date().getDate(),
      nd: new Date().getDate(),
      id: new Date().getDate(),
      kong: 0,
      kongh: 0,
      dayinfo: [],
      data: [],
      isshow: false,
      isgetdayinfo: false
    };
  },
  created() {
    if (this.type == 1) {
      this.kind = 1;
    } else {
      this.kind = 0;
    }
    this.first_setdata();
  },
  watch: {
    year: function () {
      this.setdata();
    },
    month: function () {
      this.setdata();
    },
    day: function () {
      this.setdata();
    }
  },
  methods: {
    daycolor: function (i) {
      let d = this.data[i];
      if (d['teshujieri']) {
        return '#ff9800';
      }
      if (d['gonglijieri']) {
        return '#f00';
      }
      if (d['nonglijieri']) {
        return '#f0f';
      }
      if (d['jieqi']) {
        return '#15bb2b';
      }
      return '#6c6c6c';
    },
    nongli: function (i) {
      let d = this.data[i];
      if (d['teshujieri']) {
        return d['teshujieri'];
      }
      if (d['gonglijieri']) {
        return d['gonglijieri'];
      }
      if (d['nonglijieri']) {
        return d['nonglijieri'];
      }
      if (d['jieqi']) {
        return d['jieqi'];
      }
      return d['nongliri'];
    },
    calclass: function (i) {
      let res = '';
      if (i + 1 == this.id && this.year == this.iy && this.month == this.im) {
        res += ' act';
      }
      if (i + 1 == this.nd && this.ny == this.year && this.nm == this.month) {
        res += ' tday';
      }
      if (this.data[i]['iskj']) {
        res += ' kj';
      }
      return res;
    },
    setinfo: function (i) {
      this.iy = this.year;
      this.im = this.month;
      this.id = i + 1;
      this.dayinfo = this.data[i];
    },
    caldata: function (d, kd) {
      kd.forEach(v => {
        d[v - 1]['iskj'] = true;
      });
      this.data = d;
      if (this.isgetdayinfo == false) {
        this.dayinfo = this.data[this.day - 1];
        this.isgetdayinfo = true;
      }
      this.kong = this.weeks.indexOf(d[0].week);
      this.kongh = Math.ceil((d.length + this.kong) / 7) * 7 - (d.length + this.kong);
      this.isshow = true;
    },
    showdata: function (data, ym) {
      let kjdate = JSON.parse(sessionStorage.getItem('kjdate_' + ym));
      if (kjdate) {
        this.caldata(data, kjdate.str.split(','));
      } else {
        axios.get(this.api + '/kjdate?ym=' + ym).then(result => {
          if (result.data.data !== null) {
            this.caldata(data, result.data.data.str.split(','));
            sessionStorage.setItem('kjdate_' + ym, JSON.stringify(result.data.data));
          } else {
            this.caldata(data, []);
          }
        });
      }
    },
    setdata: function () {
      this.isshow = false;
      let ym = this.year + '' + this.month;
      if (this.month < 10) {
        ym = this.year + '0' + this.month;
      }
      let data = JSON.parse(localStorage.getItem('wnl_' + ym));
      if (data) {
        this.showdata(data, ym);
      } else {
        this.getdata(ym);
      }
    },
    getdata: function (ym) {
      axios.post(this.api + '/wnl', {
        'ym': ym
      }).then(result => {
        if (result.data.code == 1) {
          localStorage.setItem('wnl_' + ym, JSON.stringify(result.data.data));
          this.showdata(result.data.data, ym);
        } else {
          console.log('请求失败！');
        }
      });
    },
    first_setdata: function () {
      let ym = this.year + '' + this.month;
      if (this.month < 10) {
        ym = this.year + '0' + this.month;
      }
      let data = JSON.parse(sessionStorage.getItem('wnl_' + ym));
      if (data) {
        this.showdata(data, ym);
      } else {
        axios.post(this.api + '/wnl', {
          'ym': ym
        }).then(result => {
          if (result.data.code == 1) {
            sessionStorage.setItem('wnl_' + ym, JSON.stringify(result.data.data));
            this.showdata(result.data.data, ym);
          } else {
            console.log('请求失败！');
          }
        });
      }
    }
  }
};