import { createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5490c146"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "title"
};
const _hoisted_2 = {
  class: "list"
};
const _hoisted_3 = {
  class: "titles"
};
const _hoisted_4 = {
  style: {
    "color": "red"
  }
};
const _hoisted_5 = {
  class: "nd_plural"
};
const _hoisted_6 = {
  class: "sxname"
};
import nav_header from '@/components/nav_header.vue';
import axios from "axios"; //原生的axios 
import { onBeforeMount, ref } from 'vue';
export default {
  __name: 'record',
  setup(__props) {
    let numarray = ref([{
      "year": "2023",
      "periods": "135",
      "open_number": ["06", "31", "29", "21", "18", "02", "32"],
      "attr": ["木", "金", "火", "木", "土", "金", "金"],
      "color": ["green", "blue", "red", "green", "red", "red", "green"],
      "sx": ["狗", "鸡", "猪", "羊", "狗", "虎", "猴"],
      "create_time": "2023-05-15 21:51:00"
    }]);
    let yearsss = ref(null);
    onBeforeMount(() => {
      var datesc = new Date();
      let year = datesc.getFullYear();
      // if(localStorage.getItem('record')==null){
      yearsss.value = year;
      axiosrecord(year);
      // }
      // else{
      //     numarray.value = JSON.parse(localStorage.getItem('record'));
      // }
    });

    let axiosrecord = year => {
      let url = 'https://jl.tukuapi.com/api/get_record?lottery_id=5&year=';
      axios.get(url).then(res => {
        let data = res.data.data;
        data.forEach(item => {
          item.attr = item.attr.split(',');
          item.open_number = item.open_number.split(',');
          item.sx = item.sx.split(',');
          item.color = item.color.split(',');
        });
        localStorage.setItem(`record`, JSON.stringify(data));
        numarray.value = data;
      });
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(nav_header, {
        name: "历史开奖"
      }), _createElementVNode("div", _hoisted_1, _toDisplayString(_unref(yearsss)) + "年历史开奖记录", 1), _createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(numarray), (s, i) => {
        return _openBlock(), _createElementBlock("div", {
          class: "item",
          key: i
        }, [_createElementVNode("div", _hoisted_3, [_createTextVNode("第 "), _createElementVNode("span", _hoisted_4, _toDisplayString(s.year + s.periods), 1), _createTextVNode("期开奖结果 ")]), _createElementVNode("div", _hoisted_5, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(s.open_number, (item, index) => {
          return _openBlock(), _createElementBlock("li", {
            key: index
          }, [_createElementVNode("div", {
            class: _normalizeClass('num ' + s.color[index])
          }, [_createElementVNode("span", null, _toDisplayString(item), 1)], 2), _createElementVNode("div", _hoisted_6, _toDisplayString(s.sx[index]) + "/" + _toDisplayString(s.attr[index]), 1)]);
        }), 128))])])]);
      }), 128))])], 64);
    };
  }
};