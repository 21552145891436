import "core-js/modules/es.array.push.js";
import { createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, isRef as _isRef, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-32a88414"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "tk-sigin"
};
const _hoisted_2 = {
  class: "tk-info"
};
const _hoisted_3 = {
  class: "tk-name"
};
const _hoisted_4 = {
  class: "tk-tiems"
};
const _hoisted_5 = {
  class: "tk-jifeng"
};
import nav_header from '@/components/nav_header.vue';
import { balance } from '@/util/http';
import { reactive, toRefs } from 'vue';

import { ref } from 'vue';
export default {
  __name: 'siginRecord',
  setup(__props) {
    const loading = ref(false);
    const finished = ref(false);
    let data = reactive({
      balances: [],
      page: 1
    });
    let {
      balances
    } = toRefs(data);
    let balanceFun = page => {
      balance({
        page: page
      }).then(res => {
        let datas = res.data.data;
        if (datas.length == 0) {
          finished.value = true;
        } else {
          loading.value = false;
          data.balances.push(...datas);
        }
      });
    };
    const onLoad = () => {
      balanceFun(data.page);
      data.page++;
    };
    return (_ctx, _cache) => {
      const _component_van_list = _resolveComponent("van-list");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(nav_header, {
        name: "签到记录"
      }), _createVNode(_component_van_list, {
        loading: _unref(loading),
        "onUpdate:loading": _cache[0] || (_cache[0] = $event => _isRef(loading) ? loading.value = $event : null),
        finished: _unref(finished),
        "finished-text": "没有更多了",
        onLoad: onLoad
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(balances), (item, index) => {
          return _openBlock(), _createElementBlock("li", {
            key: index
          }, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(item.change_type), 1), _createElementVNode("div", _hoisted_4, _toDisplayString(item.create_time), 1)]), _createElementVNode("div", _hoisted_5, "+" + _toDisplayString(item.amount), 1)]);
        }), 128))])])]),
        _: 1
      }, 8, ["loading", "finished"])], 64);
    };
  }
};