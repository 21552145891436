import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1ff1fc38"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "tail"
};
const _hoisted_2 = {
  class: "user_info"
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = ["src"];
export default {
  __name: 'otherTail',
  props: {
    navlist: {
      type: Array,
      default: [{
        name: '赞',
        num: '0',
        src: require('@/assets/image/zan.png')
      }, {
        name: ' ',
        num: '0',
        src: require('@/assets/image/look.png')
      }, {
        name: '评论',
        num: '0',
        src: require('@/assets/image/commont.png')
      }, {
        name: '收藏',
        num: '0',
        src: require('@/assets/image/wujiaoxin.png')
      }]
    }
  },
  emits: ['infoFn'],
  setup(__props, {
    emit: emits
  }) {
    const prpo = __props;
    let userInfo = item => {
      emits('infoFn', item);
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.navlist, (item, index) => {
        return _openBlock(), _createElementBlock("p", {
          key: index,
          onClick: $event => _unref(userInfo)(item)
        }, [_createElementVNode("img", {
          src: item.src,
          alt: ""
        }, null, 8, _hoisted_4), _createElementVNode("span", null, _toDisplayString(item.num) + " " + _toDisplayString(item.name), 1)], 8, _hoisted_3);
      }), 128))])]);
    };
  }
};