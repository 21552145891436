import "core-js/modules/es.array.push.js";
export default {
  name: 'Tongji',
  props: {},
  data() {
    return {
      showinfo: 0,
      yixuanList: [],
      //交集
      yixuan: true,
      boxList: [{
        title: '生肖',
        content: [{
          name: '龙',
          num: [1, 13, 25, 37, 49]
        }, {
          name: '兔',
          num: [2, 14, 26, 38]
        }, {
          name: '虎',
          num: [3, 15, 27, 39]
        }, {
          name: '牛',
          num: [4, 16, 28, 40]
        }, {
          name: '鼠',
          num: [5, 17, 29, 41]
        }, {
          name: '猪',
          num: [6, 18, 30, 42]
        }, {
          name: '狗',
          num: [7, 19, 31, 43]
        }, {
          name: '鸡',
          num: [8, 20, 32, 44]
        }, {
          name: '猴',
          num: [9, 21, 33, 45]
        }, {
          name: '羊',
          num: [10, 22, 34, 46]
        }, {
          name: '马',
          num: [11, 23, 35, 47]
        }, {
          name: '蛇',
          num: [12, 24, 36, 48]
        }]
      }, {
        title: '波色',
        content: [{
          name: '红',
          num: [1, 2, 7, 8, 12, 13, 18, 19, 23, 24, 29, 30, 34, 35, 40, 45, 46]
        }, {
          name: '绿',
          num: [5, 6, 11, 16, 17, 21, 22, 27, 28, 32, 33, 38, 39, 43, 44, 49]
        }, {
          name: '蓝',
          num: [3, 4, 9, 10, 14, 15, 20, 25, 26, 31, 36, 37, 41, 42, 47, 48]
        }]
      }, {
        title: '五行',
        content: [{
          name: '金',
          num: [2, 3, 10, 11, 24, 25, 32, 33, 40, 41]
        }, {
          name: '木',
          num: [6, 7, 14, 15, 22, 23, 36, 37, 44, 45]
        }, {
          name: '水',
          num: [12, 13, 20, 21, 28, 29, 42, 43]
        }, {
          name: '火',
          num: [1, 8, 9, 16, 17, 30, 31, 38, 39, 46, 47]
        }, {
          name: '土',
          num: [4, 5, 18, 19, 26, 27, 34, 35, 48, 49]
        }]
      }, {
        title: '大小',
        content: [{
          name: '大',
          num: [25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49]
        }, {
          name: '小',
          num: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]
        }]
      }, {
        title: '单双',
        content: [{
          name: '单',
          num: [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29, 31, 33, 35, 37, 39, 41, 43, 45, 47, 49]
        }, {
          name: '双',
          num: [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48]
        }]
      }, {
        title: '大小单双',
        content: [{
          name: '大单',
          num: [25, 27, 29, 31, 33, 35, 37, 39, 41, 43, 45, 47, 49]
        }, {
          name: '小单',
          num: [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23]
        }, {
          name: '大双',
          num: [26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48]
        }, {
          name: '小双',
          num: [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24]
        }]
      }, {
        title: '波色单双',
        content: [{
          name: '红单',
          num: [1, 7, 13, 19, 23, 29, 35, 45]
        }, {
          name: '红双',
          num: [2, 8, 12, 18, 24, 30, 34, 40, 46]
        }, {
          name: '绿单',
          num: [5, 11, 17, 21, 27, 33, 39, 43, 49]
        }, {
          name: '绿双',
          num: [6, 16, 22, 28, 32, 38, 44]
        }, {
          name: '蓝单',
          num: [3, 9, 15, 25, 31, 37, 41, 47]
        }, {
          name: '蓝双',
          num: [4, 10, 14, 20, 26, 36, 42, 48]
        }]
      }, {
        title: '七段',
        content: [{
          name: '1段',
          num: [1, 2, 3, 4, 5, 6, 7]
        }, {
          name: '2段',
          num: [8, 9, 10, 11, 12, 13, 14]
        }, {
          name: '3段',
          num: [15, 16, 17, 18, 19, 20, 21]
        }, {
          name: '4段',
          num: [22, 23, 24, 25, 26, 27, 28]
        }, {
          name: '5段',
          num: [29, 30, 31, 32, 33, 34, 35]
        }, {
          name: '6段',
          num: [36, 37, 38, 39, 40, 41, 42]
        }, {
          name: '7段',
          num: [43, 44, 45, 46, 47, 48, 49]
        }]
      }, {
        title: '五门',
        content: [{
          name: '1门',
          num: [1, 2, 3, 4, 5, 6, 7, 8, 9]
        }, {
          name: '2门',
          num: [10, 11, 12, 13, 14, 15, 16, 17, 18]
        }, {
          name: '3门',
          num: [19, 20, 21, 22, 23, 24, 25, 26, 27]
        }, {
          name: '4门',
          num: [28, 29, 30, 31, 32, 33, 34, 35, 36, 37]
        }, {
          name: '5门',
          num: [38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49]
        }]
      }, {
        title: '头数',
        content: [{
          name: '0头',
          num: [1, 2, 3, 4, 5, 6, 7, 8, 9]
        }, {
          name: '1头',
          num: [10, 11, 12, 13, 14, 15, 16, 17, 18, 19]
        }, {
          name: '2头',
          num: [20, 21, 22, 23, 24, 25, 26, 27, 28, 29]
        }, {
          name: '3头',
          num: [30, 31, 32, 33, 34, 35, 36, 37, 38, 39]
        }, {
          name: '4头',
          num: [40, 41, 42, 43, 44, 45, 46, 47, 48, 49]
        }]
      }, {
        title: '尾数',
        content: [{
          name: '0尾',
          num: [10, 20, 30, 40]
        }, {
          name: '1尾',
          num: [1, 11, 21, 31, 41]
        }, {
          name: '2尾',
          num: [2, 12, 22, 32, 42]
        }, {
          name: '3尾',
          num: [3, 13, 23, 33, 43]
        }, {
          name: '4尾',
          num: [4, 14, 24, 34, 44]
        }, {
          name: '5尾',
          num: [5, 15, 25, 35, 45]
        }, {
          name: '6尾',
          num: [6, 16, 26, 36, 46]
        }, {
          name: '7尾',
          num: [7, 17, 27, 37, 47]
        }, {
          name: '8尾',
          num: [8, 18, 28, 38, 48]
        }, {
          name: '9尾',
          num: [9, 19, 29, 39, 49]
        }]
      }, {
        title: '合数',
        content: [{
          name: '合01',
          num: [1, 10]
        }, {
          name: '合02',
          num: [2, 11, 20]
        }, {
          name: '合03',
          num: [3, 12, 21, 30]
        }, {
          name: '合04',
          num: [4, 13, 22, 31, 40]
        }, {
          name: '合05',
          num: [5, 14, 23, 32, 41]
        }, {
          name: '合06',
          num: [6, 15, 24, 33, 42]
        }, {
          name: '合07',
          num: [7, 16, 25, 34, 43]
        }, {
          name: '合08',
          num: [8, 17, 26, 35, 44]
        }, {
          name: '合09',
          num: [9, 18, 27, 36, 45]
        }, {
          name: '合10',
          num: [19, 28, 37, 46]
        }, {
          name: '合11',
          num: [29, 38, 47]
        }, {
          name: '合12',
          num: [39, 48]
        }, {
          name: '合13',
          num: [49]
        }]
      }, {
        title: '合单双',
        content: [{
          name: '合单',
          num: [1, 3, 5, 7, 9, 10, 12, 14, 16, 18, 21, 23, 25, 27, 29, 30, 32, 34, 36, 38, 41, 43, 45, 47, 49]
        }, {
          name: '合双',
          num: [2, 4, 6, 8, 11, 13, 15, 17, 19, 20, 22, 24, 26, 28, 31, 33, 35, 37, 39, 40, 42, 44, 46, 48]
        }]
      }, {
        title: '余七',
        content: [{
          name: '6余0',
          num: [6, 12, 18, 24, 30, 36, 42, 48]
        }, {
          name: '6余1',
          num: [1, 7, 13, 19, 25, 31, 37, 43, 49]
        }, {
          name: '6余2',
          num: [2, 8, 14, 20, 26, 32, 38, 44]
        }, {
          name: '6余3',
          num: [3, 9, 15, 21, 27, 33, 39, 45]
        }, {
          name: '6余4',
          num: [4, 10, 16, 22, 28, 34, 40, 46]
        }, {
          name: '6余5',
          num: [5, 11, 17, 23, 29, 35, 41, 47]
        }]
      }, {
        title: '余六',
        content: [{
          name: '7余0',
          num: [7, 14, 21, 28, 35, 42, 49]
        }, {
          name: '7余1',
          num: [1, 8, 15, 22, 29, 36, 43]
        }, {
          name: '7余2',
          num: [2, 9, 16, 23, 30, 37, 44]
        }, {
          name: '7余3',
          num: [3, 10, 17, 24, 31, 38, 45]
        }, {
          name: '7余4',
          num: [4, 11, 18, 25, 32, 39, 46]
        }, {
          name: '7余5',
          num: [5, 12, 19, 26, 33, 40, 47]
        }, {
          name: '7余6',
          num: [6, 13, 20, 27, 34, 41, 48]
        }]
      }, {
        title: '其他',
        content: [{
          name: '家禽',
          num: [4, 6, 7, 8, 10, 11, 16, 18, 19, 20, 22, 23, 28, 30, 31, 32, 34, 35, 40, 42, 43, 44, 46, 47]
        }, {
          name: '野兽',
          num: [1, 2, 3, 5, 9, 12, 13, 14, 15, 17, 21, 24, 25, 26, 27, 29, 33, 36, 37, 38, 39, 41, 45, 48, 49]
        }, {
          name: '单笔',
          num: [1, 5, 6, 8, 11, 12, 13, 17, 18, 20, 23, 24, 25, 29, 30, 32, 35, 36, 37, 41, 42, 44, 47, 48]
        }, {
          name: '双笔',
          num: [2, 3, 4, 7, 9, 10, 14, 15, 16, 19, 21, 22, 26, 27, 28, 31, 33, 34, 38, 39, 40, 43, 45, 46, 49]
        }, {
          name: '女肖',
          num: [2, 6, 7, 8, 10, 12, 14, 18, 20, 22, 24, 26, 30, 32, 34, 36, 38, 42, 44, 46, 48]
        }, {
          name: '男肖',
          num: [1, 3, 4, 5, 9, 11, 13, 15, 16, 17, 18, 19, 21, 23, 25, 27, 28, 29, 31, 33, 35, 37, 39, 40, 41, 43, 47, 49]
        }, {
          name: '吉美',
          num: [1, 2, 8, 10, 11, 12, 13, 14, 20, 22, 23, 24, 25, 26, 32, 34, 35, 36, 37, 38, 44, 46, 47, 48]
        }, {
          name: '凶丑',
          num: [3, 4, 5, 6, 7, 9, 15, 16, 17, 18, 19, 21, 27, 28, 29, 30, 31, 33, 39, 40, 41, 42, 43, 45, 49]
        }, {
          name: '天肖',
          num: [1, 2, 4, 6, 9, 11, 13, 14, 16, 18, 21, 23, 25, 26, 28, 30, 33, 35, 37, 38, 40, 42, 45, 47]
        }, {
          name: '地肖',
          num: [3, 5, 7, 8, 10, 12, 15, 17, 19, 20, 22, 24, 27, 29, 31, 32, 34, 36, 39, 41, 43, 44, 46, 48, 49]
        }, {
          name: '阴性',
          num: [1, 5, 6, 7, 11, 12, 13, 17, 18, 19, 23, 24, 25, 29, 30, 31, 35, 36, 37, 41, 42, 43, 47, 48]
        }, {
          name: '阳性',
          num: [2, 3, 4, 8, 9, 10, 14, 15, 16, 20, 21, 22, 26, 27, 28, 32, 33, 34, 38, 39, 40, 44, 45, 46, 49]
        }, {
          name: '白边',
          num: [3, 4, 5, 6, 7, 8, 15, 16, 17, 18, 19, 20, 27, 28, 29, 30, 31, 32, 39, 40, 41, 42, 43, 44]
        }, {
          name: '黑中',
          num: [1, 2, 9, 10, 11, 12, 13, 14, 21, 22, 23, 24, 25, 26, 33, 34, 35, 36, 37, 38, 45, 46, 47, 48, 49]
        }, {
          name: '红肖',
          num: [2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35, 38, 41, 44, 47]
        }, {
          name: '蓝肖',
          num: [3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36, 39, 42, 45, 48]
        }, {
          name: '绿肖',
          num: [1, 4, 7, 10, 12, 16, 19, 22, 25, 28, 31, 34, 37, 40, 43, 46, 49]
        }, {
          name: '琴',
          num: [2, 8, 12, 14, 20, 24, 26, 32, 36, 38, 44, 48]
        }, {
          name: '棋',
          num: [4, 5, 7, 16, 17, 19, 28, 29, 31, 40, 41, 43]
        }, {
          name: '书',
          num: [1, 3, 11, 13, 15, 23, 25, 27, 35, 37, 39, 47, 49]
        }, {
          name: '画',
          num: [6, 9, 10, 18, 21, 22, 30, 33, 34, 42, 45, 46]
        }, {
          name: '五福肖',
          num: [2, 3, 5, 9, 12, 14, 15, 17, 21, 24, 26, 27, 29, 33, 36, 38, 39, 41, 45, 48]
        }]
      }],
      selectNum: [] //选中的号码对象数组
    };
  },

  created() {},
  computed: {
    //根据号码添加class名
    getCss: function () {
      return function (n) {
        if (n < 10) {
          n = '0' + parseInt(n);
        }
        return 'css' + n;
      };
    }
  },
  filters: {
    //各位数字前面添加0
    numToStr: function (value) {
      if (value < 10) {
        return '0' + value;
      } else {
        return value;
      }
    }
  },
  watch: {},
  methods: {
    //复制结果
    copy() {
      let d = this.yixuanList;
      let r = [];
      for (var i = 0; i < d.length; i++) {
        if (d[i] < 10) {
          r[i] = '0' + d[i];
        } else {
          r[i] = d[i];
        }
      }
      r = r.join(',');
      const input = document.createElement('input');
      document.body.appendChild(input);
      input.setAttribute('value', r);
      input.setAttribute('readonly', 'readonly');
      input.select();
      input.setSelectionRange(0, 999);
      if (document.execCommand('copy')) {
        this.showinfo = 1;
        setTimeout(() => {
          this.showinfo = 0;
        }, 1000);
      }
      document.body.removeChild(input);
    },
    select: function (item, index, k) {
      if (item.active) {
        item.active = false;
        // Vue.set(item,'active',false);  //为item添加不存在的属性，需要使用vue提供的Vue.set( object, key, value )方法
        var name = item.name;
        var newArr = this.selectNum.reduce((total, current) => {
          current.name !== name && total.push(current);
          return total;
        }, []);
        this.selectNum = newArr;
      } else {
        item.active = true;
        item.i = k;
        // Vue.set(item,'active',true);
        // Vue.set(item,'i',k);
        let arr = this.selectNum.concat(item);
        this.selectNum = arr;
      }
      if (this.selectNum.length > 0) {
        var iarr = [];
        for (var j = 0; j < this.selectNum.length; j++) {
          var n = this.selectNum[j].i;
          if (iarr[n]) {
            iarr[n] = iarr[n].concat(this.selectNum[j].num);
          } else {
            iarr[n] = this.selectNum[j].num;
          }
        }
        var jarr = [];
        for (var a = 0; a < iarr.length; a++) {
          if (iarr[a]) {
            if (jarr.length == 0) {
              jarr = iarr[a];
            } else {
              jarr = jarr.filter(item => iarr[a].includes(item));
            }
          } else {
            continue;
          }
        }
        this.yixuanList = jarr.sort(function (a, b) {
          return a - b;
        });
      } else {
        this.yixuanList = [];
      }
    },
    //清空已选号码
    rstSelect: function () {
      for (var i = 0; i < this.boxList.length; i++) {
        for (var j = 0; j < this.boxList[i]['content'].length; j++) {
          // Vue.set(this.boxList[i]['content'][j],'active',false);
          this.boxList[i]['content'][j].active = false;
        }
      }
      this.yixuanList = [];
      this.selectNum = [];
    }
  }
};