import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-17680a5e"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["href"];
const _hoisted_2 = ["src"];
import { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/scss/pagination';
import { indexs } from '../util/http.js';

import { ref, onBeforeMount } from 'vue';
export default {
  __name: 'swipers',
  setup(__props) {
    let swiperImg = ref('');
    // 在modules加入要使用的模块
    const modules = [Autoplay, Pagination];
    onBeforeMount(() => {
      indexs().then(res => {
        swiperImg.value = res.data.carousel_list;
      });
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(_unref(Swiper), {
        modules: modules,
        loop: true,
        "slides-per-view": 1,
        "space-between": 50,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false
        },
        pagination: {
          clickable: true
        }
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(swiperImg), (item, index) => {
          return _openBlock(), _createBlock(_unref(SwiperSlide), {
            key: index
          }, {
            default: _withCtx(() => [_createElementVNode("a", {
              href: item.url
            }, [_createElementVNode("img", {
              src: item.cover,
              alt: ""
            }, null, 8, _hoisted_2)], 8, _hoisted_1)]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 1
      });
    };
  }
};