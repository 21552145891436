import { showToast } from 'vant';
import 'vant/es/toast/style';
export default {
  name: 'Tongji',
  props: {},
  data() {
    return {
      jxList: [{
        num: '01',
        sx: '兔',
        wx: '金'
      }, {
        num: '02',
        sx: '虎',
        wx: '金'
      }, {
        num: '03',
        sx: '牛',
        wx: '土'
      }, {
        num: '04',
        sx: '鼠',
        wx: '土'
      }, {
        num: '05',
        sx: '猪',
        wx: '木'
      }, {
        num: '06',
        sx: '狗',
        wx: '木'
      }, {
        num: '07',
        sx: '鸡',
        wx: '火'
      }, {
        num: '08',
        sx: '猴',
        wx: '火'
      }, {
        num: '09',
        sx: '羊',
        wx: '金'
      }, {
        num: '10',
        sx: '马',
        wx: '金'
      }, {
        num: '11',
        sx: '蛇',
        wx: '水'
      }, {
        num: '12',
        sx: '龙',
        wx: '水'
      }, {
        num: '13',
        sx: '兔',
        wx: '木'
      }, {
        num: '14',
        sx: '虎',
        wx: '木'
      }, {
        num: '15',
        sx: '牛',
        wx: '火'
      }, {
        num: '16',
        sx: '鼠',
        wx: '火'
      }, {
        num: '17',
        sx: '猪',
        wx: '土'
      }, {
        num: '18',
        sx: '狗',
        wx: '土'
      }, {
        num: '19',
        sx: '鸡',
        wx: '水'
      }, {
        num: '20',
        sx: '猴',
        wx: '水'
      }, {
        num: '21',
        sx: '羊',
        wx: '木'
      }, {
        num: '22',
        sx: '马',
        wx: '木'
      }, {
        num: '23',
        sx: '蛇',
        wx: '金'
      }, {
        num: '24',
        sx: '龙',
        wx: '金'
      }, {
        num: '25',
        sx: '兔',
        wx: '土'
      }, {
        num: '26',
        sx: '虎',
        wx: '土'
      }, {
        num: '27',
        sx: '牛',
        wx: '水'
      }, {
        num: '28',
        sx: '鼠',
        wx: '水'
      }, {
        num: '29',
        sx: '猪',
        wx: '火'
      }, {
        num: '30',
        sx: '狗',
        wx: '火'
      }, {
        num: '31',
        sx: '鸡',
        wx: '金'
      }, {
        num: '32',
        sx: '猴',
        wx: '金'
      }, {
        num: '33',
        sx: '羊',
        wx: '土'
      }, {
        num: '34',
        sx: '马',
        wx: '土'
      }, {
        num: '35',
        sx: '蛇',
        wx: '木'
      }, {
        num: '36',
        sx: '龙',
        wx: '木'
      }, {
        num: '37',
        sx: '兔',
        wx: '火'
      }, {
        num: '38',
        sx: '虎',
        wx: '火'
      }, {
        num: '39',
        sx: '牛',
        wx: '金'
      }, {
        num: '40',
        sx: '鼠',
        wx: '金'
      }, {
        num: '41',
        sx: '猪',
        wx: '水'
      }, {
        num: '42',
        sx: '狗',
        wx: '水'
      }, {
        num: '43',
        sx: '鸡',
        wx: '木'
      }, {
        num: '44',
        sx: '猴',
        wx: '木'
      }, {
        num: '45',
        sx: '羊',
        wx: '火'
      }, {
        num: '46',
        sx: '马',
        wx: '火'
      }, {
        num: '47',
        sx: '蛇',
        wx: '土'
      }, {
        num: '48',
        sx: '龙',
        wx: '土'
      }, {
        num: '49',
        sx: '兔',
        wx: '水'
      }],
      list: [{
        num: '??',
        sx: '-',
        wx: '-'
      }, {
        num: '??',
        sx: '-',
        wx: '-'
      }, {
        num: '??',
        sx: '-',
        wx: '-'
      }, {
        num: '??',
        sx: '-',
        wx: '-'
      }, {
        num: '??',
        sx: '-',
        wx: '-'
      }, {
        num: '??',
        sx: '-',
        wx: '-'
      }, {
        num: '??',
        sx: '-',
        wx: '-'
      }]
    };
  },
  created() {},
  computed: {
    //根据号码添加class名
    getCss: function () {
      return function (n) {
        if (n < 10) {
          n = '0' + parseInt(n);
        }
        return 'css' + n;
      };
    }
  },
  filters: {},
  watch: {},
  methods: {
    //复制结果
    copy() {
      let d = this.list;
      let r = [];
      for (var i = 0; i < d.length; i++) {
        if (d[i].num < 10) {
          r[i] = '0' + d[i].num;
        } else {
          r[i] = d[i].num;
        }
      }
      r = r.join(',');
      const input = document.createElement('input');
      document.body.appendChild(input);
      input.setAttribute('value', r);
      input.setAttribute('readonly', 'readonly');
      input.select();
      input.setSelectionRange(0, 999);
      if (document.execCommand('copy')) {
        showToast("复制成功");
      }
      document.body.removeChild(input);
    },
    getNum: function () {
      let list = this.jxList;
      let arr = list.slice();
      for (let i = 0; i < arr.length; i++) {
        let j = this.getRandomInt(0, i);
        let t = arr[i];
        arr[i] = arr[j];
        arr[j] = t;
      }
      return arr.slice(0, 7);
    },
    jxStart: function () {
      this.list = [{
        num: '??',
        sx: '-',
        wx: '-'
      }, {
        num: '??',
        sx: '-',
        wx: '-'
      }, {
        num: '??',
        sx: '-',
        wx: '-'
      }, {
        num: '??',
        sx: '-',
        wx: '-'
      }, {
        num: '??',
        sx: '-',
        wx: '-'
      }, {
        num: '??',
        sx: '-',
        wx: '-'
      }, {
        num: '??',
        sx: '-',
        wx: '-'
      }];
      this.list = this.getNum();
    },
    getRandomInt: function (min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    }
  }
};