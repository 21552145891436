import "core-js/modules/es.array.push.js";
import { createVNode as _createVNode, unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, isRef as _isRef, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6a2d262e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "cont"
};
const _hoisted_2 = {
  class: "list"
};
const _hoisted_3 = {
  class: "img"
};
const _hoisted_4 = ["src"];
const _hoisted_5 = {
  class: "info"
};
const _hoisted_6 = {
  class: "names"
};
const _hoisted_7 = {
  class: "title"
};
const _hoisted_8 = {
  class: "desc"
};
const _hoisted_9 = ["onClick"];
import nav_header from '@/components/nav_header.vue';
import { follow, cancel_follow } from '@/util/http.js';
import { showToast } from 'vant';
import 'vant/es/toast/style';
import { onBeforeMount } from 'vue';

import { reactive, ref } from 'vue';
export default {
  __name: 'cares',
  setup(__props) {
    let caresList = reactive([]);
    const loading = ref(false);
    const finished = ref(false);
    let data = reactive({
      caresList: [],
      page: 1
    });
    onBeforeMount(() => {
      follows(1);
    });
    const onLoad = () => {
      data.page++;
      follows(data.page);
    };
    let follows = page => {
      follow({
        type: 2,
        page: page
      }).then(res => {
        let datas = res.data.data;
        if (res.message == "用户未登录或已过期") {
          showToast(res.message);
          return false;
        }
        if (datas.length == 0) {
          finished.value = true;
        } else {
          loading.value = false;
          data.caresList.push(...datas);
        }
      });
    };
    let cancel = (id, index) => {
      cancel_follow({}, id).then(res => {
        data.caresList.splice(index, 1);
        showToast(res.message);
      });
    };
    return (_ctx, _cache) => {
      const _component_van_empty = _resolveComponent("van-empty");
      const _component_van_list = _resolveComponent("van-list");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(nav_header, {
        name: "我的关注"
      }), _createElementVNode("div", _hoisted_1, [_createVNode(_component_van_list, {
        loading: _unref(loading),
        "onUpdate:loading": _cache[0] || (_cache[0] = $event => _isRef(loading) ? loading.value = $event : null),
        finished: _unref(finished),
        "finished-text": "没有更多了",
        onLoad: onLoad
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_unref(data).caresList.length == 0 ? (_openBlock(), _createBlock(_component_van_empty, {
          key: 0,
          style: {
            "margin-top": "100px"
          },
          description: "目前还没有关注哦"
        })) : (_openBlock(true), _createElementBlock(_Fragment, {
          key: 1
        }, _renderList(_unref(data).caresList, (item, index) => {
          return _openBlock(), _createElementBlock("div", {
            class: "item",
            key: index
          }, [_createElementVNode("div", _hoisted_3, [_createElementVNode("img", {
            src: item.udata == null ? '' : item.udata.avatar,
            alt: ""
          }, null, 8, _hoisted_4)]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, _toDisplayString(item.udata == null ? '' : item.udata.nickname), 1), _createElementVNode("div", _hoisted_8, _toDisplayString(item.create_time), 1)]), _createElementVNode("div", {
            class: "cares",
            onClick: $event => _unref(cancel)(item.id, index)
          }, "已关注", 8, _hoisted_9)])]);
        }), 128))])]),
        _: 1
      }, 8, ["loading", "finished"])])], 64);
    };
  }
};