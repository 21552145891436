import { createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-58508672"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["innerHTML"];
import nav_header from '@/components/nav_header.vue';
import { onBeforeMount, ref } from 'vue';
export default {
  __name: 'api',
  setup(__props) {
    let conten = ref('');
    onBeforeMount(() => {
      conten.value = JSON.parse(localStorage.getItem('apis'));
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(nav_header, {
        name: "接口调用"
      }), _createElementVNode("div", {
        class: "miscePage openapi",
        innerHTML: _unref(conten)
      }, null, 8, _hoisted_1)], 64);
    };
  }
};