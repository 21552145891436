
import logos from "@/views/domlogin.vue";


const components = {
  logos,

}

const component = (app) => {
  Object.keys(components).forEach((key) => {
    app.component(`${key}`, components[key])
  })
}

export default component
