import "core-js/modules/es.array.push.js";
import { createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
import nav_header from '@/components/nav_header.vue';
import search from '@/components/search.vue';
import axios from 'axios';

import { ref, reactive, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
export default {
  __name: 'galleryList',
  setup(__props) {
    const loading = ref(false);
    const finished = ref(false);
    let datas = reactive({
      page: 1,
      list: [],
      indexList: [],
      listold: []
    });
    const router = useRouter();
    onBeforeMount(() => {
      axiosTuku(1);
    });
    let axiosTuku = () => {
      let url = `https://jl.tukuapi.com/api/tuku_list?lottery_type=2&page_max=1000&is_new=1`;
      let masonry = [];
      axios.get(url).then(res => {
        let data = res.data.data.data;
        let letter = res.data.data.letter;
        letter.forEach((c, d) => {
          if (c == "《") {
            c = '*';
          }
          masonry.push({
            letter: c,
            dataList: []
          });
          data.forEach((item, i) => {
            if (item.letter == c) {
              masonry[d].dataList.push(item);
            }
          });
        });
        datas.indexList = letter;
        datas.list = masonry;
        datas.listold = masonry;
      });
    };
    let desc = item => {
      router.push({
        path: `/gallery/detail/${item.id}/${item.issue}`
      });
    };
    const onLoad = () => {
      // let tims = setTimeout(()=>{

      datas.page++;
      axiosTuku(datas.page);

      // },500)
    };

    let onsubmit = data => {
      if (data.length == 0) {
        datas.list = datas.listold;
      } else {
        let array = fuzzyQuery(datas.list, data);
        let c = [];
        array.forEach((item, index) => {
          if (item.dataList.length != 0) {
            c.push(item);
          }
        });
        datas.list = c;
      }
    };
    let onleBlur = e => {
      datas.list = datas.listold;
    };
    /**
    * 使用indexof方法实现模糊查询
    * @param  {Array}  list     进行查询的数组
    * @param  {String} keyWord  查询的关键词
    * @return {Array}           查询的结果
    */
    let fuzzyQuery = (list, keyWord) => {
      var arr = [];
      for (var i = 0; i < list.length; i++) {
        arr.push({
          letter: list[i].letter,
          dataList: []
        });
        for (var j = 0; j < list[i].dataList.length; j++) {
          if (list[i].dataList[j].name.indexOf(keyWord) >= 0) {
            arr[i].dataList.push(list[i].dataList[j]);
          }
        }
      }
      return arr;
    };
    return (_ctx, _cache) => {
      const _component_van_index_anchor = _resolveComponent("van-index-anchor");
      const _component_van_cell = _resolveComponent("van-cell");
      const _component_van_index_bar = _resolveComponent("van-index-bar");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(nav_header, {
        name: "快乐8图库"
      }), _createVNode(search, {
        onSubmit: _unref(onsubmit),
        onOnleBlur: _unref(onleBlur)
      }, null, 8, ["onSubmit", "onOnleBlur"]), _createVNode(_component_van_index_bar, {
        "index-list": _unref(datas).indexList
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(datas).list, (item, index) => {
          return _openBlock(), _createElementBlock("div", {
            key: index
          }, [_createVNode(_component_van_index_anchor, {
            index: item.letter
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(item.letter), 1)]),
            _: 2
          }, 1032, ["index"]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.dataList, (items, indexs) => {
            return _openBlock(), _createBlock(_component_van_cell, {
              key: indexs,
              onClick: $event => _unref(desc)(items),
              title: items.name
            }, null, 8, ["onClick", "title"]);
          }), 128))]);
        }), 128))]),
        _: 1
      }, 8, ["index-list"])], 64);
    };
  }
};