import "core-js/modules/es.array.push.js";
import { createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, isRef as _isRef, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-75ce4aff"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "information"
};
const _hoisted_2 = {
  class: "list"
};
const _hoisted_3 = ["onClick"];
import nav_header from '@/components/nav_header.vue';
import search from '@/components/search.vue';
import axios from 'axios';

import { ref, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
export default {
  __name: 'information',
  setup(__props) {
    const loading = ref(false);
    const finished = ref(false);
    let dataList = ref([{
      name: '热门资料',
      id: '1'
    }]);
    let dataListnew = ref([]);
    const router = useRouter();
    const onLoad = () => {};

    /**
    * 使用indexof方法实现模糊查询
    * @param  {Array}  list     进行查询的数组
    * @param  {String} keyWord  查询的关键词
    * @return {Array}           查询的结果
    */
    let fuzzyQuery = (list, keyWord) => {
      var arr = [];
      for (var i = 0; i < list.length; i++) {
        if (list[i].name.indexOf(keyWord) >= 0) {
          arr.push(list[i]);
        }
      }
      return arr;
    };
    let dataInfo = data => {
      router.push({
        path: `/information/list/${data.id}/${data.name}`
      });
    };
    onBeforeMount(() => {
      artFun();
    });
    let onsubmit = data => {
      if (data.length == 0) {
        dataList.value = dataListnew.value;
        return false;
      }
      dataList.value = fuzzyQuery(dataList.value, data);
    };
    let artFun = () => {
      let url = 'https://am.zlapi8.com/api/art';
      axios.get(url).then(res => {
        let data = res.data.data;
        if (data.length == 0) {
          finished.value = true;
        } else {
          loading.value = false;
          dataList.value = data;
          dataListnew.value.push(...data);
        }
      });
    };
    return (_ctx, _cache) => {
      const _component_van_list = _resolveComponent("van-list");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(nav_header, {
        name: "资料大全"
      }), _createVNode(search, {
        onSubmit: _unref(onsubmit)
      }, null, 8, ["onSubmit"]), _createElementVNode("div", _hoisted_1, [_createVNode(_component_van_list, {
        loading: _unref(loading),
        "onUpdate:loading": _cache[0] || (_cache[0] = $event => _isRef(loading) ? loading.value = $event : null),
        finished: _unref(finished),
        "finished-text": "没有更多了",
        onLoad: onLoad
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(dataList), (item, index) => {
          return _openBlock(), _createElementBlock("div", {
            class: "item",
            key: index,
            onClick: $event => _unref(dataInfo)(item)
          }, _toDisplayString(item.name), 9, _hoisted_3);
        }), 128))])]),
        _: 1
      }, 8, ["loading", "finished"])])], 64);
    };
  }
};