import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-34b2c409"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "prompt"
};
const _hoisted_2 = {
  class: "conte"
};
export default {
  __name: 'prompt',
  props: {
    msg: {
      type: String,
      default: '提示'
    },
    hidden: {
      type: Boolean,
      default: false
    }
  },
  setup(__props) {
    const prop = __props;
    return (_ctx, _cache) => {
      return __props.hidden ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("p", null, _toDisplayString(__props.msg), 1)])])) : _createCommentVNode("", true);
    };
  }
};