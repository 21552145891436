export default {
  name: 'vm',
  props: {},
  data() {
    return {
      kind: 0,
      //类型
      bs: 0,
      wx: 0,
      sx: 0,
      setTime: 2,
      rolStr: '快',
      rolTime: 2,
      freeze: false,
      rolling: false,
      wheelDeg: 0,
      prizeNumber: 8,
      arr: [['红', '蓝', '绿', '红', '蓝', '绿'], ['金', '木', '水', '火', '土'], ['鼠', '牛', '虎', '兔', '龙', '蛇', '马', '羊', '猴', '鸡', '狗', '猪']]
    };
  },
  created() {},
  computed: {
    zhuanList() {
      return this.arr[this.kind];
    }
  },
  filters: {},
  watch: {
    prizeNumber() {
      this.freeze = true;
      this.wheelDeg = 0;
      setTimeout(() => {
        this.freeze = false;
      }, 0);
    }
  },
  methods: {
    setRol() {
      // console.log(this.setTime);
      var set = {
        '2': '快',
        '8': '中',
        '14': '慢'
      };
      this.rolStr = set[this.setTime];
    },
    onClickRotate() {
      if (this.rolling) {
        return;
      }
      this.rolTime = this.setTime;
      this.rolling = true;
      const {
        wheelDeg,
        zhuanList
      } = this;
      const random = Math.floor(Math.random() * zhuanList.length);
      this.wheelDeg = wheelDeg - wheelDeg % 360 + 6 * 360 + (360 - 360 / zhuanList.length * random);
      setTimeout(() => {
        this.rolling = false;
        if (this.kind == 0) {
          this.bs = this.zhuanList[random];
        }
        if (this.kind == 1) {
          this.wx = this.zhuanList[random];
        }
        if (this.kind == 2) {
          this.sx = this.zhuanList[random];
        }
      }, this.rolTime * 1000 + 500);
    }
  }
};