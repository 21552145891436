import "core-js/modules/es.array.push.js";
import { createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, isRef as _isRef, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-45ffb508"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "box"
};
const _hoisted_2 = {
  class: "head"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  class: "info"
};
const _hoisted_5 = {
  class: "name"
};
const _hoisted_6 = {
  class: "num"
};
const _hoisted_7 = ["onClick"];
const _hoisted_8 = {
  class: "tiems"
};
const _hoisted_9 = {
  class: "title"
};
import nav_header from '@/components/nav_header.vue';
import { author_article_list } from '@/util/http';
import { reactive } from 'vue';

import { useRoute, useRouter } from 'vue-router';
import { onMounted, ref } from 'vue';
export default {
  __name: 'articleRecord',
  setup(__props) {
    const route = useRoute();
    let router = useRouter();
    onMounted(() => {
      let id = route.params.id;
      history(1, id);
    });
    let data = reactive({
      page: 1,
      historyList: [],
      userInfo: {},
      total: 0
    });
    let showss = ref(false);
    let history = (page, id) => {
      author_article_list({
        page: page
      }, id).then(res => {
        if (res.code == '320') {
          showss.value = true;
          return false;
        }
        let datas = res.data.data;
        data.total = res.data.total;
        data.userInfo = datas[0].user;
        data.historyList.push(...datas);
      });
    };
    let historyFun = data => {
      router.push({
        path: `/detail/${data.id}/0`
      });
    };
    return (_ctx, _cache) => {
      const _component_logos = _resolveComponent("logos");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(nav_header, {
        name: "历史帖子"
      }), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("img", {
        src: _unref(data).userInfo.avatar,
        alt: ""
      }, null, 8, _hoisted_3), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, _toDisplayString(_unref(data).userInfo.nickname), 1), _createElementVNode("div", _hoisted_6, " 帖子数：" + _toDisplayString(_unref(data).total), 1)])]), _createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(data).historyList, (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          key: index,
          onClick: $event => _unref(historyFun)(item)
        }, [_createElementVNode("div", _hoisted_8, _toDisplayString(item.create_time), 1), _createElementVNode("div", _hoisted_9, _toDisplayString(item.title), 1)], 8, _hoisted_7);
      }), 128))])]), _createVNode(_component_logos, {
        shows: _unref(showss),
        "onUpdate:shows": _cache[0] || (_cache[0] = $event => _isRef(showss) ? showss.value = $event : showss = $event)
      }, null, 8, ["shows"])], 64);
    };
  }
};