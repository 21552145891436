import request from './service'//导入已经写好的拦截器
 
// 封装所有的API接口
 
export function login(params){

  return request({
    url:'login',
    method :'post',
    params:params,
  })
}
export function register(params){

    return request({
      url:'register',
      method :'post',
      params:params,
    })
}
export function codeImg(params){
    return request({
      url:'get_code',
      method :'get',
      params:params,
    })
}

export function article_type(params){
    return request({
      url:'article_type',
      method :'get',
      params:params,
    })
}

export function article_add(params){
    return request({
      url:'article_add',
      method :'post',
      params:params,
    })
}

export function article_index(params){
    return request({
      url:'article_index',
      method :'get',
      params:params,
    })
}

export function article_detail(params,url){
    return request({
      url:'article_detail/'+url,
      method :'get',
      params:params,
    })
}

export function add_follow(params,url){
  return request({
    url:'add_follow/'+url,
    method :'get',
    params:params,
  })
}

export function comment_list(params){
  return request({
    url:'comment_list',
    method :'get',
    params:params,
  })
}

export function comment_add(params,url){
  return request({
    url:'comment_add/'+url,
    method :'post',
    params:params,
  })
}

export function cancel_follow(params,url){
  return request({
    url:'cancel_follow/'+url,
    method :'get',
    params:params,
  })
}
export function article_like(params,url){
  return request({
    url:'article_like/'+url,
    method :'get',
    params:params,
  })
}

export function article_coll(params,url){//收藏
  return request({
    url:'article_coll/'+url,
    method :'get',
    params:params,
  })
}

export function change(params){
    return request({
      url:'change',
      method :'PUT',
      params:params,
    })
}

export function follow(params){//关注
    return request({
      url:'follow_list',
      method :'get',
      params:params,
    })
}


export function tukuDetail(params){//获取图库信息
  return request({
    url:'tuku_detail',
    method :'get',
    params:params,
  })
}

export function article_store(params){//收藏
  return request({
    url:'article_store',
    method :'get',
    params:params,
  })
}
export function self_comment(params){//评论
  return request({
    url:'self_comment',
    method :'get',
    params:params,
  })
}
export function article_list(params){//评论
  return request({
    url:'article_list',
    method :'get',
    params:params,
  })
}

export function cancel_article(params){//取消收藏
  return request({
    url:'cancel_article',
    method :'get',
    params:params,
  })
}
export function author_article_list(params,url){//收藏
  return request({
    url:'author_article_list/'+url,
    method :'get',
    params:params,
  })
}

export function quick_comment(params){//快捷评论
  return request({
    url:'quick_comment',
    method :'get',
    params:params,
  })
}

export function indexs(params){//轮播图
  return request({
    url:'index',
    method :'get',
    params:params,
  })
}

export function uploads(params){//轮播图
  return request({
    url:'/upload_file',
    method :'post',
    params:params,
  })
}

export function leave_comments(params){//意见反馈
  return request({
    url:'user/leave_comments',
    method :'post',
    params:params,
  })
}

export function leave_list(params){//意见反馈列表
  return request({
    url:'user/leave_list',
    method :'get',
    params:params,
  })
}

export function punch_num(params){//签到列表
  return request({
    url:'user/punch_num',
    method :'get',
    params:params,
  })
}
export function punch(params){//签到列表
  return request({
    url:'user/punch',
    method :'get',
    params:params,
  })
}
export function adv(params){//友站
  return request({
    url:'/adv',
    method :'get',
    params:params,
  })
}

export function balance(params){//签到详情
  return request({
    url:'user/change_balance',
    method :'get',
    params:params,
  })
}
export function siba_comment(params){//评论info
  return request({
    url:'siba_comment',
    method :'get',
    params:params,
  })
}
export function last_content(params){//上一期发帖
  return request({
    url:'user/last_content',
    method :'get',
    params:params,
  })
}
export function tuku_list(params){//上一期发帖
  return request({
    url:'/tuku_list',
    method :'get',
    params:params,
  })
}























