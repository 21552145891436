export default {
  name: 'vm',
  props: {},
  data() {
    return {
      nDate: '2021-08-20',
      //当天日期
      birthday: '2000-01-01',
      //生日
      sxarr: ['鼠', '牛', '虎', '兔', '龙', '蛇', '马', '羊', '猴', '鸡', '狗', '猪'],
      sxshow: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      //生肖反面
      able: 1,
      //1正面2反面
      selcot: 0,
      //已选择的生肖数
      xingyun: [] //给出的生肖
    };
  },

  created() {
    this.nDate = this.getCurrentTime();
  },
  computed: {},
  filters: {},
  watch: {},
  methods: {
    //以生日为随机数种子计算
    calsx() {
      var bir = this.birthday.split('-');
      var nday = this.nDate.split('-');
      var n = [parseInt(bir[0]), parseInt(nday[0]), parseInt(bir[2]), parseInt(nday[2]), parseInt(bir[1]), parseInt(nday[1])];
      var a = (n[0] % n[2] + n[1]) * n[4] + n[5] * n[4] - n[3];
      var b = (n[2] * n[3] + n[0] - n[1] % n[4]) % n[5] * (n[2] + n[3]);
      var c = n[0] % (n[2] * n[4]) + (n[1] - n[3]) % n[5] - n[2];
      var n1 = (a + b) * n[3] + n[2] + (n[1] - n[0]) * (n[2] + n[3]);
      var n2 = (a + c) * n[2] + n[3] + (n[1] - n[0]) * (n[2] + n[3]);
      var n3 = (b + c) * n[2] * n[3] + (n[1] + n[0]) - (n[2] + n[3]);
      n1 = parseInt(this.seededRandom(n1, 1, 1200)) % 12;
      n2 = parseInt(this.seededRandom(n2, 1, 1100)) % 11;
      n3 = parseInt(this.seededRandom(n3, 1, 1000)) % 10;
      var sx = ['鼠', '牛', '虎', '兔', '龙', '蛇', '马', '羊', '猴', '鸡', '狗', '猪'];
      var sx1 = sx.splice(n1, 1)[0];
      var sx2 = sx.splice(n2, 1)[0];
      var sx3 = sx.splice(n3, 1)[0];
      this.xingyun = [sx1, sx2, sx3];
    },
    //随机函数
    seededRandom(seed, min, max) {
      max = max || 1;
      min = min || 0;
      seed = (seed * 9301 + 49297) % 233280;
      var rnd = seed / 233280.0;
      return min + rnd * (max - min);
    },
    //点击生肖
    sel(i) {
      let c = this;
      if (this.sxshow[i] || this.selcot >= 3) {
        return false; //正面不可点击
      } else {
        this.sxarr[i] = this.xingyun[this.selcot]; //计算的生肖
        this.selcot += 1;
        setTimeout(function () {
          c.sxshow[i] = 1; //翻面
        }, 2);
      }
    },
    //开始测算
    sub(able) {
      if (able) {
        //动画次数
        for (var i = 0; i < 13; i++) {
          var vm = this;
          (function (i) {
            setTimeout(function () {
              if (i < 13) {
                // this.sxarr = this.shuffle(this.sxarr);
                vm.sxarr = vm.shuffle(vm.sxarr); //打乱动画
              }

              if (i < 11 && i > 4) {
                // 每次翻面两个生肖
                vm.sxshow[2 * (i - 4) - 1] = 0;
                vm.sxshow[2 * (i - 4) - 2] = 0;
                // vm.$set(vm.sxshow,2*(i-4)-1,0);  
                // vm.$set(vm.sxshow,2*(i-4)-2,0);
              }

              if (i == 12) {
                vm.able = 0;
                vm.calsx();
                // vm.able = 0;//动画结束
                // vm.calsx();//计算生肖
              }
            }, i * 350);
          })(i);
        }
      } else {
        //重置界面
        this.sxarr = ['鼠', '牛', '虎', '兔', '龙', '蛇', '马', '羊', '猴', '鸡', '狗', '猪'];
        this.sxshow = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
        this.able = 1; //
        this.selcot = 0;
        this.xingyun = []; //清空生肖
      }
    },

    getCurrentTime() {
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      let nDate = yy + '-' + mm + '-' + dd;
      return nDate;
    },
    // 返回min和max之间的一个随机数，包括min和max
    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min); // +1是保证可以取到上限值
    },

    // 洗牌函数
    shuffle(arr) {
      let _arr = arr.slice(); // 下面会讲到slice的特别之处
      for (let i = 0; i < _arr.length; i++) {
        let j = this.getRandomInt(0, i);
        let t = _arr[i];
        _arr[i] = _arr[j];
        _arr[j] = t;
      }
      return _arr;
    }
  }
};