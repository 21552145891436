import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1b51da1b"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "year"
};
const _hoisted_2 = ["onClick"];
const _hoisted_3 = {
  class: "qishu"
};
const _hoisted_4 = {
  class: "qushu_nav"
};
const _hoisted_5 = ["onClick"];
const _hoisted_6 = {
  key: 0,
  class: "footer"
};
const _hoisted_7 = {
  class: "popup"
};
const _hoisted_8 = ["onClick"];
import { watch } from "vue";

import { ref, onMounted } from 'vue';
export default {
  __name: 'years',
  props: {
    qishuArray: {
      type: Array,
      default: []
    },
    issue_year: {
      type: Array,
      default: []
    },
    record: {
      type: String,
      default: ''
    }
  },
  emits: ['yearFuc', 'qishuFun', 'qishuTun'],
  setup(__props, {
    emit: emits
  }) {
    const props = __props;
    let height = ref(100);
    let bottom = ref('100%');

    // let record = ref();
    // watch(props,(val,oldval)=>{

    //     record.value = val.qishuArray[0]
    // })

    const myRef = ref(null);
    const myRefs = ref(null);
    let acitveIndex = ref('0');
    let yearIndex = ref(0);
    let clientWidth = 78;
    onMounted(() => {});
    let look_popo = () => {
      if (bottom.value == 0) {
        bottom.value = '100%';
      } else {
        bottom.value = 0;
      }
    };
    let qishuClick = (e, index, num) => {
      clientWidth = e.target.clientWidth;
      acitveIndex.value = index;
      // record.value = num
      emits('qishuFun', num);
    };
    let qishuClickTow = (index, num) => {
      myRefs.value.scrollLeft = clientWidth * index;
      acitveIndex.value = index;
      // record.value = num;
      bottom.value = 100;
      emits('qishuTun', num);
      emits('qishuFun', num);
    };
    let yearFu = (item, index) => {
      yearIndex.value = index;
      myRefs.value.scrollLeft = 0;
      acitveIndex.value = 0;
      emits('yearFuc', item);
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        class: "tiems",
        ref_key: "myRef",
        ref: myRef
      }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.issue_year, (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          class: _normalizeClass(_unref(yearIndex) == index ? 'active' : ''),
          key: index,
          onClick: $event => _unref(yearFu)(item, index)
        }, _toDisplayString(item) + "年", 11, _hoisted_2);
      }), 128))])]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", {
        class: "qishu_tow",
        ref_key: "myRefs",
        ref: myRefs
      }, [_createElementVNode("div", _hoisted_4, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.qishuArray, (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          class: _normalizeClass(_unref(acitveIndex) == index ? 'active' : ''),
          key: index,
          onClick: $event => _unref(qishuClick)($event, index, item)
        }, "第" + _toDisplayString(item) + "期", 11, _hoisted_5);
      }), 128))])])], 512), _createElementVNode("div", {
        class: "qushu_num",
        onClick: _cache[0] || (_cache[0] = (...args) => _unref(look_popo) && _unref(look_popo)(...args))
      }, [_createElementVNode("div", {
        class: _normalizeClass(_unref(bottom) == 0 ? 'c b' : 'c')
      }, "第" + _toDisplayString(__props.record) + "期", 3)])]), _unref(bottom) == 0 ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.qishuArray, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: _normalizeClass(_unref(acitveIndex) == index ? 'item active' : 'item'),
          key: index,
          onClick: $event => _unref(qishuClickTow)(index, item)
        }, "第" + _toDisplayString(item) + "期", 11, _hoisted_8);
      }), 128))])])) : _createCommentVNode("", true)], 512);
    };
  }
};