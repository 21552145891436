
// import { Message, Loading } from 'element-ui'
// const ConfigBaseURL = 'https://www.389666a.com/api/' //默认路径，这里也可以使用env来判断环境
const ConfigBaseURL = 'https://api.6ctkapi.com/api/' //默认路径，这里也可以使用env来判断环境



import axios from "axios";//原生的axios
//用来拦截用的
axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
//创建一个单例
const http= axios.create({
  baseURL:ConfigBaseURL,
  timeout:5000,//响应时间

})
 
//拦截器  -请求拦截
http.interceptors.request.use(config=>{
  //部分接口需要token
  let token=localStorage.getItem('Authorization');
  if(token){
    config.headers.Authorization=token;
 
  }
  return config;
},err=>{
  return Promise.reject(err)
})

//拦截器  -响应拦截
http.interceptors.response.use(res=>{
  

  if (res.status ==0 || res.status == 200) {
    if(res.data.message =="登录过期!"){
        localStorage.removeItem('userInfo')
        localStorage.removeItem('Authorization')
        localStorage.removeItem('chat')
        // Message()
        
    }
    // if(res.data.data.code==320){

    // }
    // code值为 0 或 200 时视为成功
    return Promise.resolve(res.data)

  }
 return Promise.reject(res)

},err=>{
 
  return Promise.reject(err)

});
 
//整体导出
export default http;
 

