import "core-js/modules/es.array.push.js";
import { showToast } from 'vant';
import 'vant/es/toast/style';
export default {
  name: 'vm',
  props: {},
  data() {
    return {
      showinfo: 0,
      balls: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49'],
      balli: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      nums: [],
      fsList: [{
        name: '二中二',
        n: 0,
        arr: [],
        i: 0
      }, {
        name: '三中三',
        n: 0,
        arr: [],
        i: 0
      }, {
        name: '四中四',
        n: 0,
        arr: [],
        i: 0
      }, {
        name: '五中五',
        n: 0,
        arr: [],
        i: 0
      }],
      z2list: [{
        name: '中二',
        n: 0
      }, {
        name: '中三',
        n: 0
      }, {
        name: '中四',
        n: 0
      }, {
        name: '中五',
        n: 0
      }, {
        name: '中六',
        n: 0
      }],
      z3list: [{
        name: '中三',
        n: 0
      }, {
        name: '中四',
        n: 0
      }, {
        name: '中五',
        n: 0
      }, {
        name: '中六',
        n: 0
      }],
      z4list: [{
        name: '中四',
        n: 0
      }, {
        name: '中五',
        n: 0
      }, {
        name: '中六',
        n: 0
      }]
    };
  },
  created() {},
  computed: {},
  filters: {},
  watch: {},
  methods: {
    //复制结果
    copy() {
      let d = [].concat(this.nums);
      console.log(d);
      // d = d.sort(function (a,b){ return a-b });
      if (d.length == 0) {
        return false;
      }
      let r = d.join(',');
      const input = document.createElement('input');
      document.body.appendChild(input);
      input.setAttribute('value', r);
      input.setAttribute('readonly', 'readonly');
      input.select();
      input.setSelectionRange(0, 999);
      if (document.execCommand('copy')) {
        this.showinfo = 1;
        setTimeout(() => {
          this.showinfo = 0;
        }, 1000);
      }
      document.body.removeChild(input);
    },
    //查询计算
    cal() {
      var arr = [].concat(this.nums);
      arr = arr.sort(function (a, b) {
        return a - b;
      });
      let n = arr.length;
      for (var i = 0; i < 4; i++) {
        this.fsList[i].n = this.zuhe(arr, i + 2).length;
        this.fsList[i].arr = this.zuhe(arr, i + 2);
      }
      this.z2list[0].n = n - 2;
      this.z2list[1].n = (n - 3) * 3;
      this.z2list[2].n = (n - 4) * 6;
      this.z2list[3].n = (n - 5) * 10;
      this.z2list[4].n = (n - 6) * 15;
      if (n > 2) {
        this.z3list[0].n = 1;
      }
      if (n > 3) {
        this.z4list[0].n = 1;
        this.z3list[1].n = 4;
      }
      if (n > 4) {
        this.z4list[1].n = 5;
        this.z3list[2].n = 10;
      }
      if (n > 5) {
        this.z4list[2].n = 15;
        this.z3list[3].n = 20;
      }
    },
    //清空
    clear() {
      this.balli = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      this.nums = [];
      this.fsList = [{
        name: '二中二',
        n: 0,
        arr: [],
        i: 0
      }, {
        name: '三中三',
        n: 0,
        arr: [],
        i: 0
      }, {
        name: '四中四',
        n: 0,
        arr: [],
        i: 0
      }, {
        name: '五中五',
        n: 0,
        arr: [],
        i: 0
      }];
      this.z2list = [{
        name: '中二',
        n: 0
      }, {
        name: '中三',
        n: 0
      }, {
        name: '中四',
        n: 0
      }, {
        name: '中五',
        n: 0
      }, {
        name: '中六',
        n: 0
      }];
      this.z3list = [{
        name: '中三',
        n: 0
      }, {
        name: '中四',
        n: 0
      }, {
        name: '中五',
        n: 0
      }, {
        name: '中六',
        n: 0
      }];
      this.z4list = [{
        name: '中四',
        n: 0
      }, {
        name: '中五',
        n: 0
      }, {
        name: '中六',
        n: 0
      }];
    },
    //选择
    select(i) {
      if (this.nums.length > 19) {
        return false;
      }
      if (this.balli[i] == 0) {
        this.nums.push(this.balls[i]);
        this.balli[i] = 1;
      }
    },
    //组合
    zuhe(arr, num) {
      var result = [];
      var range = function (r, _arr) {
        if (r.length == num) {
          result.push(r);
        } else {
          let l = r.length;
          for (var i = 0, len = _arr.length - num + l; i <= len; i++) {
            range(r.concat(_arr[i]), _arr.slice(i + 1));
          }
        }
      };
      range([], arr);
      return result;
    }
  }
};