import axios from 'axios';
import Zoushi from '@/views/Common/Tools/zoushi.vue';
import Tongji from '@/views/Common/Tools/tongji.vue';
import Yilou from '@/views/Common/Tools/yilou.vue';
import Kjdate from '@/views/Common/Tools/kjdate.vue';
import Tiaoma from '@/views/Common/Tools/tiaoma.vue';
import Jixuan from '@/views/Common/Tools/jixuan.vue';
import Xingyun from '@/views/Common/Tools/xingyun.vue';
import Boxiao from '@/views/Common/Tools/boxiao.vue';
import Fushi from '@/views/Common/Tools/fushi.vue';
export default {
  components: {
    Zoushi,
    Tongji,
    Yilou,
    Kjdate,
    Tiaoma,
    Jixuan,
    Xingyun,
    Boxiao,
    Fushi
    //   ,,Kjdate,Tiaoma,Jixuan,Xingyun,Boxiao,Fushi
  },

  name: "activity",
  data() {
    return {
      api: "https://ls.kjkj.fit",
      code: ['xg', 'am', 'tw', 'xjp'],
      titles: ['工具宝箱', '走势图表', '开奖统计', '遗漏统计', '开奖日期', '挑码助手', '机选号码', '幸运生肖', '波肖转盘', '复式速查'],
      color: ['#8b52ef', '#ee4343', '#8bc34a', '#03a9f4', '#ffc107', '#f6588e', '#a35235', '#3f51b5', '#ff713e', '#975dfd'],
      id: 0,
      data: []
    };
  },
  computed: {
    typeID: function () {
      return sessionStorage.getItem("curInd") - 1;
    }
  },
  created() {
    this.getdata(this.typeID);
  },
  watch: {},
  methods: {
    fanhui() {
      if (this.id != 0) {
        this.id = 0;
      } else {
        window.history.go(-1);
      }
    },
    getdata: function (id) {
      let data = JSON.parse(sessionStorage.getItem('kjhistory_' + id));
      if (data) {
        this.data = data;
      } else {
        let d = {
          'g': 'am',
          's': 500
        };
        axios.post(this.api + '/kj', d).then(res => {
          this.data = res.data.data;
          sessionStorage.setItem('kjhistory_' + id, JSON.stringify(res.data.data));
        });
      }
    }
  }
};